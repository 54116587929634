<template>
    <zw-sidebar @shown="shown"
                :title="$t('common.form.title.createCustomerContract')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col sm="6">
                        <zw-model-selector-group v-model="form.customer"
                                                 name="customer-contract.customer"
                                                 model-type="customer"
                                                 validate="required"
                                                 :callback="customerSelected"
                        />
                    </b-col>
                    <b-col cols="6">
                        <zw-model-selector-group v-model="form.contract"
                                                 name="customer-contract.contract"
                                                 model-type="contract"
                                                 validate="required"
                                                 :callback="contractSelected"
                        />
                    </b-col>
                </b-row>
                <b-row v-if="form.options">
                    <b-col cols="7">
                        <b-table striped show-empty :items="form.options" :fields="tableFields">
                            <template #cell(name)="row">
                                <template> {{ row.item.name }}</template>
                            </template>
                            <template #cell(value)="row">
                                <template v-if="row.item.type == 'Button' || row.item.type == 'Choice'">
                                    <multiselect
                                        v-model="form.options[row.index]['value']"
                                        :options="row.item.options"
                                        select-label=""
                                    />
                                </template>
                                <template v-else>
                                    <zw-input-group v-model="form.options[row.index]['value']"
                                                    disable-label
                                                    name="values"/>
                                </template>
                            </template>
                        </b-table>
                    </b-col>
                    <b-col cols="5">
                        <b-row v-if="form.customer && form.contract">
                            <b-col class="text-right">
                                <b-button
                                    @click="reloadPreview()"
                                    class="mb-2"
                                >
                                    <font-awesome-icon icon="sync"/>
                                </b-button>
                            </b-col>
                        </b-row>

                        <iframe width="100%"
                                height="100%"
                                :style="[source ? {'min-height':'700px', 'border':'none'} : {'border': 'none'}]"
                                :src="source"
                        ></iframe>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="7">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.generate') }}
                        </b-button>
                    </b-col>
                    <b-col sm="5"></b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'customer-contract-modal',
    components: {ZwModelSelectorGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            form: {
                contract: null,
                customer: null,
                options: [],
            },
            tableFields: [
                {
                    label: this.$t('common.label.contract.name'),
                    key: 'name',
                },
                {
                    label: this.$t('common.label.contract.value'),
                    key: 'value',
                },
            ],
            labelPrefix: 'cutomser-contract.label',
            response: null,
            source: null,
            previewFinishLoad: true,
        }
    },
    mounted() {
    },
    computed: {},
    methods: {
        ...mapGetters('CustomerContract', ['getContract', 'getContractPreview']),
        shown() {
            this.loading = false
            this.form.customer = this.payload.customerId
            this.form.offering = this.payload.offeringId
            this.form.options = [];
        },
        reloadPreview() {
            let sendData = this.form
            sendData['preview'] = true
            sendData = JSON.parse(JSON.stringify(sendData))
            if (this.previewFinishLoad) {
                this.load = true
                this.previewFinishLoad = false
                this.$store.dispatch('CustomerContract/fetchContractPreview', sendData)
                    .then(() => {
                        this.source = 'data:application/pdf;base64,' + this.getContractPreview().content
                    }).finally(() => {
                    this.previewFinishLoad = true
                    this.load = false
                })
            }
        },
        customerSelected() {
            if (this.form.customer && this.form.contract) {
                this.reloadPreview()
            }
        },
        contractSelected() {
            this.$store.dispatch('ContractSettings/fetchContract', this.form.contract)
                .then((response) => {
                    let afterFilter = [];
                    for (const [key, value] of Object.entries(response.data.options)) {
                        if (value.fixed) {
                            afterFilter.push(value);
                        }
                    }
                    this.form.options = afterFilter;
                })
                .finally(() => {
                    this.loading = false;
                    if (this.form.customer && this.form.contract) {
                        this.reloadPreview()
                    }
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.form['preview'] = false;
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('CustomerContract/saveContract', formData)
                        .then((response) => {
                            this.commonAfterSave(response, this.sidebar)
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false
                                this.$refs['observer'].setErrors(errors)
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>